html,
body,
#root {
    height: 100%;
    background-color: white;
    overscroll-behavior: none;
}

.modal-content {
    background-color: rgba(255, 255, 255, 0.6);
    border: none;
    backdrop-filter: blur(5px);
}

.background-image {
    background-size: cover;
    /* Faz com que a imagem cubra todo o espaço da div */
    background-position: center;
    /* Centraliza a imagem */
    background-repeat: no-repeat;
    /* Evita a repetição da imagem */
}

th,
td {
    vertical-align: middle;
}

th {
    text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}


input:required:invalid:not(.regular),
select:required:invalid:not(.regular),
textarea:required:invalid:not(.regular) {
    border-color: red;
    background-color: #ffe6e6;
}

.required::after {
    content: " *";
    color: red;
}

.modal {
    background-color: rgba(127, 127, 127, 0.5);
    backdrop-filter: blur(5px);
}


#menu-left {
    width: 180px;
    transition: width 500ms;
    flex-shrink: 0;
}

#menu-left.contract {
    width: 40px;
}


@media screen and (max-width: 768px) {
    /* Breakpoint para "sm" */

    #menu-left {
        width: 40px !important;
    }

    .menu-label {
        display: none
    }

    .menu-title {
        visibility: hidden !important;
    }

}

.paper-print {
    width: 280mm;
    padding: 13.333mm;
    margin: 20px auto 20px auto;
    background-color: white;
    border: solid 1px black;
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.2);
}

@media print {

    body {
        zoom: 0.75;
    }

    @page {
        size: A4;
        margin: 1cm;
    }

    img,
    .image-container,
    .leaflet-container,
    .print-container {
        page-break-inside: avoid;
    }

    .page-break {
        page-break-after: always;
    }

    .btn {
        display: none;
    }

    .print-visible {
        overflow: visible !important;
    }

    .print-visibleY {
        overflow-y: visible !important;
    }

    .bg-light {
        background-color: transparent !important;
    }

    .paper-print {
        width: 100%;
        padding: 0mm;
        border: none;
        box-shadow: none;
    }

    .paper-print input:disabled,
    .paper-print select:disabled,
    .paper-print textarea:disabled {
        background-color: white;
    }

    .paper-print input:checked,
    .paper-print input:checked {
        background-color: black;
    }

    footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
    }

}